import { ref, watch } from 'vue';
import { Dehydrate, Model } from 'vue-model';
import { getApp } from '../models/App';
import { Room } from '../models/Room';
import { User } from '../models/User';
import { confirm } from '../utils';

const mapUserToUserInfo = (user: User) => {
  return {
    _id: user.id,
    name: user.name,
    moderator: user.role === 'teacher',
  };
};

export const whiteBoardRef = ref<HTMLIFrameElement | null>(null);
export const whiteBoardAPI = {
  navigation(boardId: string, enabled = true) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'disableNavigation', boardId, enabled);
      window.postMessage({ method: 'disableNavigation', args: [boardId, enabled] }, '*');
    });
  },
  readonly(boardId: string, readonly = true) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'makeReadonly', boardId, readonly);
      window.postMessage({ method: 'makeReadonly', args: [boardId, readonly] }, '*');
    });
  },
  navigate(boardId: string, index: number, local = false) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'navigate', boardId, index);
      const app = getApp();
      app.connection.currentRoom!.currentSlide = index;
      window.postMessage({ method: 'navigate', args: [boardId, index] }, '*');
      if (local) {
        // app.restrictedSend('[slide]' + [ app.connection.currentRoomName, index ].join(':'))
        // If the user has top menu control, allow them to change boards.
        app.hasTopMenuControl()
          ? app.send('[slide]' + [app.connection.currentRoomName, index, app.hasTopMenuControl()].join(':'))
          : app.restrictedSend('[slide]' + [app.connection.currentRoomName, index, app.hasTopMenuControl()].join(':'));
      }
    });
  },
  next(boardId: string) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'next', boardId);
      const app = getApp();
      // window.postMessage({ method: 'next', args: [ boardId ] }, '*')
      if (app.connection.currentRoom) {
        const index = Math.min(app.connection.currentRoom.slideNames.length - 1, app.connection.currentRoom.currentSlide + 1);
        app.connection.currentRoom.currentSlide = index;
        window.postMessage({ method: 'navigate', args: [boardId, index] }, '*');
        // app.restrictedSend('[slide]' + [ app.connection.currentRoomName, index ].join(':'))
        // If the user has top menu control, allow them to change boards.
        app.hasTopMenuControl()
          ? app.send('[slide]' + [app.connection.currentRoomName, index, app.hasTopMenuControl()].join(':'))
          : app.restrictedSend('[slide]' + [app.connection.currentRoomName, index, app.hasTopMenuControl()].join(':'));
      }
    });
  },
  prev(boardId: string) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'prev', boardId);
      // window.postMessage({ method: 'prev', args: [ boardId ] }, '*')
      const app = getApp();
      if (app.connection.currentRoom) {
        const index = Math.max(0, app.connection.currentRoom.currentSlide - 1);
        app.connection.currentRoom.currentSlide = index;
        window.postMessage({ method: 'navigate', args: [boardId, index] }, '*');
        // app.restrictedSend('[slide]' + [ app.connection.currentRoomName, index ].join(':'))
        // If the user has top menu control, allow them to change boards.
        app.hasTopMenuControl()
          ? app.send('[slide]' + [app.connection.currentRoomName, index, app.hasTopMenuControl()].join(':'))
          : app.restrictedSend('[slide]' + [app.connection.currentRoomName, index, app.hasTopMenuControl()].join(':'));
      }
    });
  },
  deleteSlide(boardId: string) {
    whiteBoardIsReadyPromise.then((window) => {
      const app = getApp();
      if (app.connection.currentRoom) {
        const index = Math.min(app.connection.currentRoom.slideNames.length - 2, app.connection.currentRoom.currentSlide);
        console.log('[~whiteboard]', 'deleteSlide', boardId);
        window.postMessage({ method: 'deleteSlide', args: [boardId] }, '*');
        whiteBoardAPI.waitConfirm().then(() => {
          app.connection.currentRoom!.currentSlide = index;
          whiteBoardAPI.navigate(boardId, index);
        });
      }
    });
  },
  deleteAllSlides(boardId: string) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'deleteAllSlides', boardId);
      window.postMessage({ method: 'deleteAllSlides', args: [boardId] }, '*');
      whiteBoardAPI.waitConfirm().then(() => {
        const app = getApp();
        app.connection.currentRoom!.currentSlide = 0;
        whiteBoardAPI.navigate(boardId, 0);
      });
    });
  },
  addSlide(boardId: string) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'addSlide', boardId);
      const index = getApp().connection.currentRoom!.currentSlide;
      window.postMessage({ method: 'addSlide', args: [boardId] }, '*');
      whiteBoardAPI.navigate(boardId, index + 1, true);
    });
  },
  setCopiedSlide(boardId: string, canvas: Dehydrate<typeof Model> | null) {
    if (canvas === null) {
      return;
    }
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'setCopiedSlide', boardId, canvas);
      window.postMessage({ method: 'setCopiedSlide', args: [boardId, canvas] }, '*');
    });
  },
  copySlidesFromBoards(boardId: string, boardIds: string[]) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'copySlidesFromBoards', boardId, boardIds);
      window.postMessage({ method: 'copySlidesFromBoards', args: [boardId, boardIds] }, '*');
    });
  },
  copySlideToBoards(boardId: string, boardIds: string[]) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'copySlideToBoards', boardId, boardIds);
      window.postMessage({ method: 'copySlideToBoards', args: [boardId, boardIds] }, '*');
    });
  },
  renameSlide(boardId: string, index: number, name: string) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'renameSlide', boardId, index, name);
      window.postMessage({ method: 'renameSlide', args: [boardId, index, name] }, '*');
    });
  },
  confirm(boardId: string, message: string, result = true) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'confirm', boardId, message);
      whiteBoardAPI.confirmPromises.forEach((resolve) => resolve());
      whiteBoardAPI.confirmPromises = [];
      window.postMessage({ method: 'confirm', args: [message, result] }, '*');
    });
  },
  addUsers(users: User[]) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'addUsers', users);
      window.postMessage({ method: 'addUsers', args: [users.map(mapUserToUserInfo)] }, '*');
    });
  },
  load(boardId: string, userInfo: Partial<User>) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'load', boardId, userInfo);
      window.postMessage({ method: 'load', args: [boardId, userInfo] }, '*');
    });
  },
  reset(boardId: string) {
    whiteBoardIsReadyPromise.then((window) => {
      console.log('[~whiteboard]', 'reset', boardId);
      window.postMessage({ method: 'reset', args: [boardId] }, '*');
    });
  },
  waitConfirm() {
    return new Promise((resolve, reject) => {
      whiteBoardAPI.confirmPromises.push(resolve);
    });
  },
  confirmPromises: [] as ((value?: any) => void)[],
};
export let whiteBoardWindowResolve: (window: Window) => any;
let whiteBoardIsReadyPromise = Promise.resolve<Window>(null as unknown as Window);
const resetWhiteBoardPromise = () => {
  whiteBoardIsReadyPromise = new Promise<Window>((resolve, reject) => {
    whiteBoardWindowResolve = resolve;
  }).then((window) => {
    console.log('[~whiteboard] Window promise resolved');

    const room = getApp().connection.currentRoom;

    if (room) {
      if (room.follow) {
        whiteBoardAPI.navigation(room.serverName, false);
        whiteBoardAPI.navigate(room.serverName, room.teachersCurrentSlide);
      }

      if (!room.getLocalUser()?.permissions.whiteboard && !getApp().isTeacher()) {
        whiteBoardAPI.readonly(room.serverName, true);
      }

      whiteBoardAPI.setCopiedSlide(room.serverName, getApp().connection.copiedSlide);

      if (room.slideNames.length && room.currentSlide) {
        whiteBoardAPI.navigate(room.serverName, room.currentSlide);
      }
    }

    return window;
  });
};
resetWhiteBoardPromise();

export const onWhiteBoardLoad = () => {
  console.log('[~whiteboard] iframe loaded');

  const iframe = whiteBoardRef.value;
  if (!iframe) throw new Error('Cant grab ref for whiteboard even though it should be loaded');

  console.log('[~whiteboard] sending connect');
  iframe.contentWindow?.postMessage('connect', '*');

  iframe.contentWindow?.postMessage(
    {
      method: 'addUsers',
      args: [User.all().map(mapUserToUserInfo)],
    },
    '*',
  );

  const app = getApp();

  iframe.contentWindow?.postMessage(
    {
      method: 'load',
      args: [app.connection.currentRoom?.serverName, mapUserToUserInfo(app.getUser())],
    },
    '*',
  );
};

window.addEventListener('message', (event) => {
  // if (event.origin !== 'http://example.org:8080') {}

  const iframe = whiteBoardRef.value;
  if (!iframe) {
    return;
  }

  if (!event.data) {
    console.log('[~whiteboard] Received empty message event');
    return;
  }

  if (event.data === 'ready') {
    console.log('[~whiteboard] board ready to use', getApp().connection?.currentRoom?.name);
    resetWhiteBoardPromise();
    whiteBoardWindowResolve(iframe.contentWindow as Window);
  }

  if (typeof event.data === 'object') {
    if (event.data.type === 'event') {
      switch (event.data.name) {
        // case 'changeSlide': {
        //   const { boardId, index } = event.data
        //   const app = getApp()

        //   const room = app.connection.currentRoom

        //   if (room) {
        //     if (room.slideChangeInProgress) {
        //       room.slideChangeInProgress = false
        //     } else {
        //       app.restrictedSend('[slide]' + [ app.connection.currentRoomName, index ].join(':'))
        //     }
        //     room.currentSlide = index
        //   }

        //   break
        // }
        case 'notifySlidesImported': {
          const index = getApp().connection.currentRoom!.currentSlide;
          whiteBoardAPI.navigate(getApp().connection.currentRoom!.serverName, index + 1);
          break;
        }
        case 'setCopiedSlide': {
          const { slide } = event.data;
          getApp().connection.copiedSlide = slide;
          break;
        }
        case 'slideNames': {
          const { names } = event.data;
          const room = getApp().connection.currentRoom;
          if (room) {
            room.slideNames = names;
          }
          break;
        }
        case 'confirm': {
          confirm(event.data.msg, (result) => whiteBoardAPI.confirm(event.data.boardId, event.data.msg, result));
          break;
        }
        case 'reportWhiteboardAction': {
          const { action } = event.data;
          const app = getApp();
          switch (action) {
            case 'drawing-start':
              // send update to server
              app.send(`[drawing]1:${app.connection.id}`);
              app.getUser().isDrawing = true;
              break;
            case 'drawing-stop':
              // send update to server
              app.send(`[drawing]0:${app.connection.id}`);
              app.getUser().isDrawing = false;
              break;
          }
          break;
        }
        case 'focusWhiteboard': {
          iframe.focus();
          break;
        }
        case 'boardSetLoading': {
          const room = getApp().connection.currentRoom as Room;
          room.boardSetLoading = event.data.status;
          getApp().connection.overlayMessage = 'Loading boards...';
          break;
        }
        case 'boardSetDeleting': {
          const room = getApp().connection.currentRoom as Room;
          room.boardSetDeleting = event.data.status;
          getApp().connection.overlayMessage = 'Deleting all boards...';
        }
      }
    }
  }
});

// watch(whiteBoardRef, (newValue, oldValue) => {
//   if (!newValue && oldValue) {
//     oldValue.removeEventListener('load', onWhiteBoardLoad)
//   }

//   if (newValue) {
//     newValue.addEventListener('load', onWhiteBoardLoad)
//   }
// })
