<template>
  <div class="top-menu">
    <div v-if="!app.isRecorder" class="left-controls">
      <label :class="{ active: app.fullscreen }">
        <i class="bx bx-fullscreen" style="font-size: 34px" />
        <input v-model="app.fullscreen" type="checkbox" hidden />
      </label>
      <label v-if="app.getUser()?.permissions?.screenshare || teacher" :class="{ active: app.isScreenShareEnabled }">
        <i class="bx bx-outline" />
        <input
          v-model="app.isScreenShareEnabled"
          type="checkbox"
          hidden
          :disabled="!app.getUser()?.permissions?.screenshare && !teacher"
        />
      </label>
      <!-- <div class="radio-group">
        <label :class="{active: app.whiteBoardOrScreen === 'whiteboard'}">
          <i class="bx bxs-edit-alt" />
          <input
            v-model="app.whiteBoardOrScreen"
            value="whiteboard"
            name="whiteBoardOrScreen"
            type="radio"
            hidden
          >
        </label>
        <label :class="{active: app.whiteBoardOrScreen === 'screenshare'}">
          <i class="bx bx-outline" />
          <input
            v-model="app.whiteBoardOrScreen"
            value="screenshare"
            name="whiteBoardOrScreen"
            type="radio"
            hidden
          >
        </label>
      </div> -->
      <label v-if="teacher" @click="sendAnnouncement">
        <i class="bx bx-detail" />
      </label>
      <label v-if="teacher" @click="showEventLog">
        <i class="bx bxs-info-square" />
      </label>
    </div>
    <div v-if="currentRoom" class="whiteboard-controls">
      <button v-if="!app.isRecorder" :disabled="follow && !app.hasTopMenuControl()" @click="renameSlide">
        <i class="bx bx-edit" />
      </button>
      <select v-model.number="currentRoom.currentSlide" :disabled="follow && !app.hasTopMenuControl()">
        <option v-for="(name, index) in currentRoom.slideNames ?? {}" :key="name" :value="index">
          {{ name }}
        </option>
      </select>
      <template v-if="!app.isRecorder">
        <button :disabled="(follow && !app.hasTopMenuControl()) || !currentRoom.currentSlide" @click="prevSlide">
          <i class="bx bx-left-arrow-alt" />
        </button>
        <button
          :disabled="(follow && !app.hasTopMenuControl()) || currentRoom.currentSlide >= currentRoom.slideNames.length - 1"
          @click="nextSlide"
        >
          <i class="bx bx-right-arrow-alt" />
        </button>
        <button :disabled="!teacher && !app.hasTopMenuControl()" @click="addSlide">
          <i class="bx bx-plus" />
        </button>
        <button
          :disabled="!teacher || (currentRoom?.slideNames.length ?? 0) <= 1"
          @click="deleteSlide"
          @click.right="deleteAllSlides"
        >
          <i class="bx bx-trash" />
        </button>
      </template>
      <label v-if="teacher" :class="{ active: app.connection?.currentRoom?.follow }">
        <input
          type="checkbox"
          :checked="app.connection?.currentRoom?.follow"
          hidden
          @change="app.setFollow(app.connection.currentRoom as any, ($event.target as HTMLInputElement).checked)"
        />
        <i class="bx bx-sync" />
      </label>
    </div>
    <div class="right-controls">
      <button v-if="teacher" :class="app.recorderState" @click="startStopRecordingCheck(app.recorderState)">
        <i />
        {{ app.recorderState === 'off' ? 'Record' : app.recorderState === 'on' ? 'Recording' : 'Pending' }}
      </button>
      <button v-if="!teacher && app.recorderState === 'on' && !app.isRecorder" :class="app.recorderState" style="cursor: default">
        <i />
        {{ app.recorderState === 'on' ? 'Recording' : 'Pending' }}
      </button>
      <button :class="{ active: time !== '00:00:00' }" :disabled="!teacher" @click="handleTimer">
        <i class="bx bxs-timer" />
        {{ time }}
      </button>
      <button v-if="!app.isRecorder" :class="{ active: app.showVideo }" @click="app.showVideo = !app.showVideo">
        <i class="bx" :class="app.showVideo ? 'bxs-hide' : 'bxs-show'" />
        {{ app.showVideo ? 'Video' : 'Video' }}
      </button>
      <button
        v-if="!app.isRecorder"
        style="width: auto; font-size: 150%; padding-left: 15px"
        @click="app.showSessionMenu = !app.showSessionMenu"
      >
        <i class="bx bx bx-menu" value="Session Menu" />
      </button>
      <div v-if="app.showSessionMenu" class="panel">
        <button v-if="teacher" class="kill-session" style="color: red; margin-bottom: 10px" @click="killSession">
          Kill Session
          <i class="bx bxs-x-circle" />
        </button>

        <hr v-if="teacher" class="divider" />

        <div class="keyboard-shortcuts">
          <div class="keyboard-shortcuts-header">Session Keyboard Shortcuts</div>
          <div class="keyboard-shortcuts-content">
            <ul>
              <li>
                <span class="keyboard-shortcut-command">CTRL + 1:</span>
                <span class="keyboard-shortcut-description">✔️ Green Check</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + 2:</span>
                <span class="keyboard-shortcut-description">❌ Red X</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + 3:</span>
                <span class="keyboard-shortcut-description">😵 Confused Face</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + 4:</span>
                <span class="keyboard-shortcut-description">✋ Raise Hand</span>
              </li>
            </ul>
          </div>
          <hr class="divider" />
          <div class="keyboard-shortcuts-header">Whiteboard Keyboard Shortcuts</div>
          <div class="keyboard-shortcuts-content">
            <ul>
              <li>
                <span class="keyboard-shortcut-command">CTRL + A:</span>
                <span class="keyboard-shortcut-description">Select All</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + C:</span>
                <span class="keyboard-shortcut-description">Copy</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + V:</span>
                <span class="keyboard-shortcut-description">Paste</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + G:</span>
                <span class="keyboard-shortcut-description">Group</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + U:</span>
                <span class="keyboard-shortcut-description">Ungroup</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + Z:</span>
                <span class="keyboard-shortcut-description">Undo</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + Y:</span>
                <span class="keyboard-shortcut-description">Redo</span>
              </li>
              <li>
                <span class="keyboard-shortcut-command">CTRL + L:</span>
                <span class="keyboard-shortcut-description">Lock</span>
              </li>
              <li>
                <span class="">CTRL + Spacebar:</span>
                <span class="keyboard-shortcut-description">Tool Change</span>
              </li>
            </ul>
          </div>
          <hr class="divider" />
          <div class="keyboard-shortcuts-header show-preferences" @click="showPreferences">Set Preferences</div>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <Dialog v-model="showSetPreferencesModal" :yes-or-no="false" :nocancel="true" @ok="setAllPreferences()">
      <div class="bings-and-dings-header">"Bings and Dings"</div>
      <div
        v-for="(value, key) in PREFERENCELIST"
        :key="key"
        class="checkbox"
        @click="setPreference(key, ($event.target as HTMLInputElement).checked)"
      >
        <label>
          <input type="checkbox" :checked="preferenceCheck(key)" />
          {{ value }}
        </label>
      </div>
      <br />
      <br />
      <div style="padding-top: 15px">
        Select/deselect in order to turn on/off sounds for each action.
        <br />
        <br />
        Note: These settings will only apply to this device/browser.
        <br />
        You can change them at any time.
      </div>
    </Dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
@import '../main.scss';

div.top-menu {
  display: flex;
  flex-wrap: wrap;
  min-height: 56px;
  background: $gray;
  align-items: center;
  color: white;
  font-size: 24px;
  flex-shrink: 0;
  z-index: 10;
  /* Ensuring menus open on top of the whiteboard / displayarea <div> */

  button,
  label {
    transition: text-shadow 0.3s;
  }

  button:hover,
  label:hover {
    text-shadow: 0px 0px 10px $icon-highlight;
  }

  > div.left-controls {
    justify-self: flex-start;
    display: flex;
    gap: 10px;
    height: 30px;
    margin-left: 20px;

    > div.radio-group {
      display: flex;
    }

    > label,
    > div.radio-group > * {
      display: flex;
      align-items: center;
      height: 30px;
      width: 30px;
      justify-content: center;
      cursor: pointer;
    }

    > label.active {
      background: $icon-highlight;
      color: black;
    }

    > div.radio-group > * {
      border-left: 1px solid black;
      border-right: 1px solid black;
      background: $icon-pressed;

      &.active {
        border-left: 1px solid white;
        border-right: 1px solid white;
        background: $icon-highlight;
        color: black;
      }
    }

    > div.radio-group > *:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: none;
    }

    > div.radio-group > *:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right: none;
    }
  }

  > div.whiteboard-controls {
    margin: auto;
    display: flex;
    height: 30px;
    gap: 10px;

    select,
    option {
      background: $gray;
      color: inherit;
      font-size: 20px;
    }

    select {
      border: 1px solid $light-gray;
      width: 200px;
    }

    button {
      color: white;
      font-size: 24px;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      &:disabled {
        color: gray;
      }
    }

    label.active {
      background: $icon-highlight;
      color: black;
    }
  }

  > div.right-controls {
    justify-self: flex-end;
    display: flex;
    align-items: stretch;
    align-self: stretch;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-left: 0px;
      border: none;
      background: $gray;
      border-left: 1px solid black;
      border-right: 1px solid black;
      width: 150px;
      color: white;
      font-size: 16px;
      font-weight: bold;

      > i {
        margin-right: 10px;
      }

      &.active,
      &.on {
        background: $icon-highlight;
        color: black;
      }

      &.on > i,
      &.off > i,
      &.pending > i {
        display: block;
        background: white;
        border: 1px solid black;
        border-radius: 100%;
        width: 10px;
        height: 10px;
      }

      &.on > i {
        background: red;
      }
    }
  }
}

div.panel {
  position: absolute;
  left: calc(100% - 250px);
  top: 56px;
  width: 250px;
  min-height: 0;
  background: $gray;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  color: white;
  overflow-x: hidden;

  > button {
    border-radius: 5px;
    background: $icon-highlight;
    border: none;
    font-weight: bold;
    color: white;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
  }

  > hr {
    width: 100%;
    color: white;
  }
}

.keyboard-shortcuts-header {
  font-weight: bold;
  font-size: 10pt;
  margin: 10px;
}

.keyboard-shortcuts-content {
  font-size: 10pt;

  > ul {
    list-style: none;
    margin-left: -30px;
  }
}

.keyboard-shortcut-command {
  width: 65px;
  display: inline-flex;
}

.keyboard-shortcut-description {
  padding-right: 10px;
}

.divider {
  width: 100%;
  color: white;
}

div.show-preferences {
  text-decoration: underline;
  cursor: pointer;
}

div.bings-and-dings-header {
  font-size: 150%;
  padding-bottom: 10px;
}
</style>

<script lang="ts">
import { getApp } from '../models/App';
import { computed, Ref, ref, watch, defineComponent } from 'vue';
import { whiteBoardAPI } from './whiteboardAPI';
import { MAIN_ROOM } from '../types';
import { alert, prompt, confirm, showConfirmationDialog } from '../utils';
// import JitsiMeetJS from '@lyno/lib-jitsi-meet'
import Dialog from './Dialog.vue';
import { config, PREFERENCELIST } from '../config';
import axios from 'axios';

export default defineComponent({
  name: 'TopMenu',
  components: {
    Dialog,
  },
  props: ['id'],
  setup(props) {
    const app = getApp();

    const currentRoom = computed(() => getApp().connection.currentRoom);

    const sendAnnouncement = async () => {
      const msg = await prompt('Announcement:');
      if (!msg) return;

      // Only teachers should have the ability to do this.
      if (!app.isTeacher()) return;

      getApp().restrictedSend('[announcement]' + msg);
    };

    const prevSlide = () => {
      // Allow changing of boards for teachers, students if they're in a BO, students if they have top menu control, or individual changing of boards in the main room if "follow" is off.
      if (app.isTeacher() || app.connection.currentRoomName !== MAIN_ROOM || app.hasTopMenuControl() || !follow.value) {
        whiteBoardAPI.prev(currentRoom.value?.serverName as string);
      }
    };

    const nextSlide = () => {
      // Allow changing of boards for teachers, students if they're in a BO, students if they have top menu control, or individual changing of boards in the main room if "follow" is off.
      if (app.isTeacher() || app.connection.currentRoomName !== MAIN_ROOM || app.hasTopMenuControl() || !follow.value) {
        whiteBoardAPI.next(currentRoom.value?.serverName as string);
      }
    };

    watch(
      () => currentRoom.value?.currentSlide,
      (slide) => {
        if (slide != null) {
          selectSlide(slide);
        }
      },
    );

    const selectSlide = (index: number) => {
      if (app.hasTopMenuControl() || app.isTeacher()) {
        whiteBoardAPI.navigate(currentRoom.value?.serverName as string, index, true);
      }
    };

    const deleteSlide = async () => {
      // Only teachers should have the ability to do this.
      if (!app.isTeacher()) return;

      if ((currentRoom.value?.slideNames.length ?? 0) <= 1) return;

      confirm('Do you really want to delete this board? This action is NOT undoable!  You will also not be able to undo/redo previous actions.', (yesOrNo) => {
        if (!yesOrNo) return;

        axios
          .get(
            `${config.whiteBoardUrl}ws/command/delete-slide/${currentRoom.value?.serverName}/${app.connection.currentRoom?.currentSlide}`,
          )
          .then(() => {
            if (currentRoom.value) {
              currentRoom.value.currentSlide = Math.max(0, currentRoom.value.currentSlide - 1);
            }
          })
          .catch(() => {
            app.status = 'There was an error deleting the slide!';
          });
      });

      // whiteBoardAPI.deleteSlide(currentRoom.value?.serverName as string);
    };

    const deleteAllSlides = async (ev: MouseEvent) => {
      ev.preventDefault();

      // Only teachers should have the ability to do this.
      if (!app.isTeacher()) return;

      confirm('Do you really want to delete the whole boardset? This action is NOT undoable!  You will also not be able to undo/redo previous actions.', (yesOrNo) => {
        if (!yesOrNo) return;

        axios
          .get(`${config.whiteBoardUrl}ws/command/delete/${currentRoom.value?.serverName}?reload=true`)
          .then(() => {
            if (currentRoom.value) {
              currentRoom.value.currentSlide = 0;
            }
          })
          .catch(() => {
            app.status = 'There was an error deleting the slides!';
          });
      });

      // whiteBoardAPI.deleteAllSlides(currentRoom.value?.serverName as string)
    };

    const addSlide = () => {
      if (app.hasTopMenuControl() || app.isTeacher()) {
        whiteBoardAPI.addSlide(currentRoom.value?.serverName as string);
      }
    };

    const handleTimer = async () => {
      if (time.value !== '00:00:00') {
        app.setTimer(0);
        return;
      }

      const msg = await prompt('hh:mm:ss');
      if (!msg) return;

      const [sec, min, h] = msg
        .split(':')
        .reverse()
        .map((n) => parseInt(n));

      app.setTimer(sec + (min ?? 0) * 60 + (h ?? 0) * 3600);
    };

    const time = ref('00:00:00');

    let timerTimeout = 0;
    const updateTimer = () => {
      let seconds = ~~(((app.timerExpirationDate ?? 0) - +new Date()) / 1000);

      // Play a sound when the timer goes off.
      if (seconds === 0) {
        app.sounds.timerDing.play();
      }

      if (seconds > 0) {
        timerTimeout = setTimeout(updateTimer, 200);
      }

      time.value = '00:00:00';

      if (seconds > 0) {
        const h = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
        const m = Math.floor(seconds / 60);
        seconds = seconds % 60;
        time.value = `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
    };

    watch(
      () => app.timerExpirationDate,
      (newValue) => {
        if (newValue) {
          clearTimeout(timerTimeout);
          updateTimer();
        }
      },
      { immediate: true },
    );

    const startStopRecordingCheck = async (onOff: string) => {
      // Starting or stopping?
      const action = onOff === 'on' ? 'stop' : 'start';

      // Make sure it wasn't an accidental click to start/stop recording.
      const yes = await confirm('Do you really want to ' + action + ' recording?', undefined, true);

      if (yes) {
        startStopRecording();
      }
    };

    const startStopRecording = () => {
      if (app.connection.currentRoomName !== MAIN_ROOM) {
        alert('Please switch to main room to start or stop recording');
      } else {
        if (app.recordingSession) {
          if (app.recordingSession.getStatus() === 'error') {
            alert('Recording errored before');
            console.log(app.recordingSession.getError());
            app.recordingSession = null;
            return;
          }

          if (app.recordingSession.getStatus() === 'pending') {
            alert('Recording not started yet');
            return;
          }

          if (app.recordingSession.getStatus() === 'off') {
            alert('Recording was already stopped');
            app.recordingSession = null;
            return;
          }

          app.connection.currentRoom?.conference
            .stopRecording(app.recordingSession.getID())
            .then(() => {
              app.recordingSession = null;

              // Update the event log about the fact that recording was stopped.
              app.logEvent('Recording was stopped.');

              //Inform everyone that the recording was stopped
              getApp().restrictedSend(`[recording]${0}`);

              // Update the recording report because the recording would have stopped, if it was going.
              app.recordingReport.push([app.recordingStartedTime, Date.now()]);
            })
            .catch((e: Node) => {
              console.log(e, app.recordingSession.getError());
              alert('Could not stop Recording');
            });
        } else {
          app.recorderState = 'pending';
          app.connection.currentRoom?.conference
            .startRecording({ appData: null, mode: JitsiMeetJS.constants.recording.mode.FILE })
            .then((session: any) => {
              app.recordingSession = session;

              // Update app.recordingStartedTime because the recording was started.
              app.recordingStartedTime = Date.now();

              // Update the recording report because the recording would have stopped, if it was going.
              // app.recordingReport.push([ app.recordingStartedTime, Date.now() ])

              //Inform everyone that the recording was started.  This is limited because the sound plays while the button still says Pending due to the creation of the Chrome instance for recording.  The setTimeout() is meant to help mitigate this limitation.
              setTimeout(() => {
                app.restrictedSend(`[recording]${1}`);
              }, 2000);

              app.logEvent('Recording was started.');
            })
            .catch((e: Node) => {
              console.log(e);
              alert('Could not start Recording');
            });
        }
      }
    };

    const teacher = computed(() => app.isTeacher());
    const follow = computed(() => !teacher.value && app.connection.currentRoom?.follow);

    const renameSlide = async () => {
      if (app.hasTopMenuControl() || app.isTeacher()) {
        const msg = await prompt('New Name:');
        if (!msg) return;
        whiteBoardAPI.renameSlide(
          currentRoom.value?.serverName as string,
          app.connection.currentRoom?.currentSlide as number,
          msg,
        );
      }
    };

    const killSession = async () => {
      const yes = await confirm('Do you really want to end this session?', undefined, true);
      if (!yes) return;
      if (app.recorderState !== 'off') {
        app.connection.currentRoom?.conference
          .stopRecording(app.recordingSession.getID())
          .then(() => {
            app.recordingSession = null;
            app.restrictedSend('[kill]');
          })
          .catch((e: Error) => {
            console.log(e, app.recordingSession.getError());
            alert('Could not stop Recording');
          });
      } else {
        app.restrictedSend('[kill]');
      }
      // unload()
    };

    const showEventLog = () => {
      alert(
        app.eventLog.map(
          (e) => new Date(e[1]).toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' }) + ': ' + e[0],
        ),
      );
    };

    // Show the preferences modal
    const showSetPreferencesModal = ref(false);
    const showPreferences = () => {
      showSetPreferencesModal.value = true;
    };

    // Set the status  of the preference
    const setPreference = (preference: string, status: boolean) => {
      localStorage.setItem(preference, JSON.stringify(status));
    };

    // Return the status of the preference
    const preferenceCheck = (preference: string) => {
      return JSON.parse(localStorage.getItem(preference));
    };

    return {
      app,
      renameSlide,
      sendAnnouncement,
      currentRoom,
      prevSlide,
      nextSlide,
      selectSlide,
      deleteSlide,
      addSlide,
      startStopRecording,
      startStopRecordingCheck,
      handleTimer,
      time,
      teacher,
      follow,
      killSession,
      showEventLog,
      deleteAllSlides,
      showPreferences,
      showSetPreferencesModal,
      setPreference,
      preferenceCheck,
      PREFERENCELIST,
    };
  },
});
</script>
